import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home/Home';
import About from '../pages/about/About';
import Contact from '../pages/contact/Contact';
import Jeweler from '../pages/jeweler/Jeweler';
import Fueloil from '../pages/fueloil/Fueloil';
import Clothing from '../pages/clothing/Clothing';
import Construction from '../pages/construction/Construction';
import GES from '../pages/ges/Ges';

function Router() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/jeweler' element={<Jeweler />} />
            <Route path='/fueloil' element={<Fueloil />} />
            <Route path='/clothing' element={<Clothing />} />
            <Route path='/construction' element={<Construction />} />
            <Route path='/ges' element={<GES />} />
        </Routes>
    );
};

export default Router;