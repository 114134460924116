import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';

function Clothing() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [
        "https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/clothing%2Fclothing2.svg?alt=media&token=cfc22018-1681-4001-9c62-44b064e21268",
        "https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/clothing%2Fclothing1.svg?alt=media&token=d53a1629-40f5-4ae2-a297-358cef520f2a",
        "https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/clothing%2Fclothing_slide_3.svg?alt=media&token=ad1b99a3-1016-4fd9-866f-3f41a58a8a9f"
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, []);

    const goToNextSlide = () => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    };

    const goToPrevSlide = () => {
        setCurrentImageIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    return (
        <>
            <Header />
            <div>
                <div className="relative mb-5 md:mb-16 lg:mb-24 overflow-hidden">
                    <img className="w-full h-auto object-cover" src={images[currentImageIndex]} alt={`Resim ${currentImageIndex + 1}`} />
                    <div className="absolute top-1/2 transform -translate-y-1/2 left-0 right-0 flex justify-between">
                        <button className="bg-gray-500 bg-opacity-50 text-white px-3 py-2 rounded-l focus:outline-none focus:ring-2 focus:ring-gray-400" onClick={goToPrevSlide}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M14.707 2.293a1 1 0 0 1 0 1.414L8.414 10l6.293 6.293a1 1 0 1 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                            </svg>
                        </button>
                        <button className="bg-gray-500 bg-opacity-50 text-white px-3 py-2 rounded-r focus:outline-none focus:ring-2 focus:ring-gray-400" onClick={goToNextSlide}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M5.293 2.293a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1 0 1.414l-7 7a1 1 0 1 1-1.414-1.414L10.586 10 5.293 4.707a1 1 0 0 1 0-1.414z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="relative mb-5 md:mb-16 lg:mb-24 text-start">
                    <img className="w-full h-24 lg:object-cover md:object-cover object-contain lg:h-48 lg:px-16 px-6 mb-4 lg:mb-8 animate-pulse" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/clothing%2Fclothing4.svg?alt=media&token=a89689f5-39b3-4f6a-878d-f74b4f0dadca" alt="Arkaplan Resmi" />
                    <div className="absolute inset-0 flex flex-col items-center justify-center text-white p-4 lg:px-48">
                        <div className="metin3 text-center">
                            <p className="text-xs md:text-xl lg:text-4xl xl:text-3xl font-bold text-yellow-600 font-serif">
                                Sadece sizin tarzınıza özel
                            </p>
                            <p className="text-xs md:text-xl lg:text-4xl xl:text-3xl font-bold text-yellow-700 font-serif">
                                seçenekler sunuyoruz
                            </p>
                        </div>
                    </div>
                </div>

                <div className="relative mb-10 lg:mb-20 shadow-xl">
                    <img className="w-full object-cover h-auto lg:px-16 px-6" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/clothing%2Fclothing_group.svg?alt=media&token=49c0fac2-5945-4a44-a3d4-248726807f9d" alt="Arkaplan Resmi" />
                </div>
            </div>
        </>
    );
};

export default Clothing;
