import React, { useState } from 'react';
import Header from '../../components/header/Header';
import { Transition } from '@headlessui/react';

const About = () => {
    const [selectedTab, setSelectedTab] = useState(null);

    const handleTabClick = (tab) => {
        setSelectedTab((prevTab) => (prevTab === tab ? null : tab)); // Toggle selected tab
    };

    return (
        <>
            <Header />
            <div className="container mx-auto px-8 py-12 lg:px-48">
                <h1 className="text-2xl lg:text-3xl font-bold text-center lg:mt-8 mt-4 lg:mb-12 font-serif text-gray-700 hover:text-gray-500">Hakkımızda</h1>
                <hr className="border-t border-gray-300 my-8 mx-auto w-3/4 mb-10 lg:mb-20" />
                <div className="mb-48 lg:mb-24">
                    <button
                        className={`text-xl lg:text-2xl font-semibold mb-8 lg:mb-12 ${selectedTab === 'misyon' ? 'text-yellow-500' : 'text-gray-700'} focus:outline-none flex items-center w-full mb-2`}
                        onClick={() => handleTabClick('misyon')}
                    >
                        Misyon
                        <svg
                            className={`w-4 h-4 ml-1 transition-transform ${selectedTab === 'misyon' ? 'transform rotate-180 text-primary' : 'text-gray-700'}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 15l7-7 7 7"
                            ></path>
                        </svg>
                    </button>
                    <Transition
                        show={selectedTab === 'misyon'}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="relative">
                            <div className="border border-gray-300 rounded-lg p-6 shadow-lg hover:bg-gray-100 transition duration-300 mb-12">
                                <div className="text-lg text-gray-700 font-style: italic">
                                    Ülke ekonomisine katma değer yaratacak ve dışa bağımlılığı azaltacak projeleri hayata geçirmek. Faaliyet gösterdiğimiz sektörlerde, uluslararası standartlara uygun ürün ve hizmetler üreterek doğru, güvenilir yatırım fırsatları oluşturmak ve bunların sürdürülebilir olmasını sağlamak. Ülkenin iş gücünü ve potansiyelini açığa çıkartarak ulusal anlamda yakaladığımız başarıyı, uluslararası arenaya taşımak. Bu sayede sürekli büyümek ve gelişmektir.
                                </div>
                            </div>
                            <div className="absolute top-0 left-0 w-full h-full border border-gray-300 rounded-lg shadow-md pointer-events-none"></div>
                        </div>

                    </Transition>
                    <button
                        className={`text-xl lg:text-2xl font-semibold mb-8 lg:mb-12 ${selectedTab === 'vizyon' ? 'text-yellow-500' : 'text-gray-700'} focus:outline-none flex items-center w-full mb-2`}
                        onClick={() => handleTabClick('vizyon')}
                    >
                        Vizyon
                        <svg
                            className={`w-4 h-4 ml-1 transition-transform ${selectedTab === 'vizyon' ? 'transform rotate-180 text-primary' : 'text-gray-700'}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 15l7-7 7 7"
                            ></path>
                        </svg>
                    </button>
                    <Transition
                        show={selectedTab === 'vizyon'}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="relative">
                            <div className="border border-gray-300 rounded-lg p-6 shadow-lg hover:bg-gray-100 transition duration-300 mb-12">
                                <div className="text-lg text-gray-700 font-style: italic">
                                    Gelecekte, dünya genelinde insanların yaşam kalitesini artırmak için lider bir konumda olmak; teknolojiyi kullanarak insanlara daha iyi bir gelecek sunmak ve topluma fayda sağlamak için sürekli olarak ileriye doğru adımlar atmaktır.</div>
                            </div>
                            <div className="absolute top-0 left-0 w-full h-full border border-gray-300 rounded-lg shadow-md pointer-events-none"></div>
                        </div>
                    </Transition>
                    <button
                        className={`text-xl lg:text-2xl font-semibold mb-8 lg:mb-12 ${selectedTab === 'tarihçe' ? 'text-yellow-500' : 'text-gray-700'} focus:outline-none flex items-center w-full`}
                        onClick={() => handleTabClick('tarihçe')}
                    >
                        Tarihçe
                        <svg
                            className={`w-4 h-4 ml-1 transition-transform ${selectedTab === 'tarihçe' ? 'transform rotate-180 text-primary' : 'text-gray-700'}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 15l7-7 7 7"
                            ></path>
                        </svg>
                    </button>
                    <Transition
                        show={selectedTab === 'tarihçe'}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="relative">
                            <div className="border border-gray-300 rounded-lg p-6 shadow-lg hover:bg-gray-100 transition duration-300 mb-12">
                                <div className="text-lg text-gray-700 font-style: italic">
                                    Firmamız, 1998 yılında birkaç vizyoner girişimcinin bir araya gelmesiyle kuruldu. O zamanlardan beri, müşteri odaklı yaklaşımımız ve sürekli olarak gelişen hizmet anlayışımız sayesinde sektörde öncü bir konuma geldik. Kuruluşumuzdan bu yana, her geçen gün müşterilerimizin beklentilerini aşmak için çabaladık ve teknolojiyle birleşen yenilikçi çözümlerimizle sürdürülebilir bir başarı elde ettik.

                                    Yıllar içinde, birçok zorluğa ve engellemeye rağmen, kararlılıkla büyüdük ve geniş bir müşteri kitlesiyle küresel pazarda tanınan bir marka olduk. Misyonumuz ve vizyonumuz doğrultusunda ilerleyerek, topluma ve çevreye olan sorumluluğumuzu da her zaman göz önünde bulundurduk.

                                    Geleceğe yönelik olarak, teknolojiye olan bağlılığımızı ve etik değerlerimizi koruyarak, daha da büyük başarılara imza atmayı hedefliyoruz. Müşterilerimize ve paydaşlarımıza değer sağlamak için çalışmaya devam ederken, toplumsal sorumluluklarımızı da unutmamak için çaba göstereceğiz.                                </div>
                            </div>
                            <div className="absolute top-0 left-0 w-full h-full border border-gray-300 rounded-lg shadow-md pointer-events-none"></div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
}

export default About;
