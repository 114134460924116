import React from "react";
import Header from '../../components/header/Header';

function GES() {
    return (
        <>
            <Header />
            <div className="flex flex-col items-center">
                <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/ges%2Fges_background.svg?alt=media&token=ab09f393-c152-466b-ad71-5454b15baac6" alt="ges" className="w-full object-cover" />
                <p className="text-3xl mt-4 px-4 sm:px-36 text-center">Güneş enerjisi santrali (GES) nedir?</p>
                <br />
                <p className="text-l px-4 sm:px-36 text-center">
                    Güneşten gelen sonsuz enerjiyi fotovoltaik modüllerin ve diğer bileşenlerin kurulumu ile elektrik enerjisine çeviren sistemlerdir. GES hesap makinelerindeki sistemler gibidir fakat büyük ebatlarda güneş pilleri kullanılır.
                    <br />
                    Fotovoltaik güneş pilleri, üzerine gelen güneş ışınlarını elektriğe çevirirler. Mevcut en temiz enerji üretim teknolojisi ve doğa dostu olan güneş enerjisi santrallerinde üretilen elektrik 10 yıl devlet garantili gelir sağlar. Aynı zamanda yapım – işletim maliyetleri ve verimli olması açısından da karlı bir enerji kaynağıdır.
                    <br />
                    GES Ana Bileşenleri Aşağıdaki Gibidir;
                    <br />
                    Fotovoltaik (PV) Panel: Yarı iletken PV hücrelerin panel üzerinde birleştirilerek, gelen güneş ışınlarından doğru akım elektrik üretilmesini sağlar.
                    <br />
                    İnvertör: PV panellerin ürettiği doğru akım elektriğini, şebeke elektriğine çevirir.
                    <br />
                    Panel Taşıyıcı Sistemi: PV panel taşıyıcı sistemleri ve montaj aparatlarından oluşur.
                    <br />
                    BOS (Balance of System): Güneş Enerjisi santrallerinin Modül, İnverter ve konstrüksiyon dışındaki kısmı BOS (Balance of System) olarak adlandırılır. Altyapı, AG – OG Kablo, Konnektör, Paralelleme Panoları, Şalt Ekipmanları, Alçak Gerilim Pano ve Salt, Trafo Postası, Orta / Yüksek Gerilim Pano ve Salt, İnşaat İşleri, Tel Çitler, Aydınlatma, Kamera Sistemleri kısımlardan oluşur.
                </p>
                <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/ges%2Fges_cover.svg?alt=media&token=a99f5370-4e7e-4558-bb87-4c3e40028fab" alt="ges-cover" className="mt-4 w-full max-h-96 object-cover" />
                <p className="text-l px-4 sm:px-36 py-4 text-center">
                    GES çalışma prensibi, üzerine ışık düşen malzemelerde gerçekleşen elektron hareketi yani fotovoltaik etki ilkesine dayanır.
                    <br />
                    Fotovoltaik piller, üzerine düşen güneş enerjisini doğrudan elektrik enerjisine çeviren yarı iletken malzemelerden oluşan düzenlerdir.
                    <br />
                    İstenen güçte elektrik enerjisi üretimini sağlamak, merkezi şebekeye bağlı elektrik üretim sistemi ile mümkündür.
                    <br />
                    Öncelikle üretilmek istenen enerji miktarı tespit edilir. Güneş ışınlarının fotovoltaik modüller üzerine temas etmesi ile DC elektrik enerjisi üretilir.
                    <br />
                    Üretilen enerji, merkezi şebekeye bağlanabilen ve yüksek çevrim gücü olan invertörler aracılığı ile merkezi şebeke sistemine bağlanır. Böylelikle üretilen enerji doğrudan şebeke sistemine gönderilmiş olur. Güneş enerjisi santralleri ile elektrik üretimi; kolay kurulum avantajının yanı sıra, uzun ömürlü, çevreci ve düşük işletme maliyeti avantajları ile kullanım kolaylığı sunan enerji üretim şeklidir. Güneşten elde edilen enerji aynı zamanda sürdürülebilir enerji özellik taşımaktadır. Yapılan analizler, güneş enerjisine yapılan yatırımın tarımdan daha kârlı olduğunu göstermektedir. Türkiye’nin iklim şartlarının oldukça elverişli olması ve diğer enerji yatırımlarına oranla güneş enerjisi santrallerinin fiyat avantajının olması, güneş enerjisi yatırımlarına olan ilgiyi gün geçtikçe arttırmaktadır. Güneş enerji santrali kurmak için birçok malzeme kullanılmakta olup, güneş enerjisi santrali yatırım maliyeti de kullanılacak malzemeye er mektedir.
                    <br />
                    GES Kurulum maliyetleri, zeminin özellikleri, yöresel olarak kar ve rüzgar yükü gibi faktörlerin değişkenlik göstermesi, arazinin eğimlerinin değişkenliği, kullanılan ekipmanlar ve tasarım durumuna göre her saha için farklılık gösteren bir konudur.
                    <br />
                    Güneş enerjisi santral yatırımları için finansman seçeneklerinin yanı sıra, devletin de çok ciddi teşvikleri vardır. GES yatırımları, diğer yenilebilir enerji kaynaklarına nazaran özel bir devlet teşviği almaktadır. Üretilen her MWh başına, 133 USD devlet garantili üretim bedeli alınabilen güneş enerjisi santrallerinde, yatırım maliyetleri de azalmaktadır.
                    <br />
                    GES Sistemi Nedir?
                    <br />
                    Güneş enerjisi sistem fiyatı/maliyeti en düşük sistemler olduğundan günümüzde tercih edilen yenilebilir enerji kaynaklarının başında gelmektedir. Ayrıca güneş enerjisi sistemleri sınırsız, temiz, depolaması kolaydır. Trafik ışıklarından fabrika çatılarına kadar GES sistemini bir çok alandan görmeniz mümkündür.
                    <br />
                    GES Açılımı Nedir?
                    <br />
                    GES’in açılımı güneş enerjisi santralidir. İngilizcesi SPP Solar Power Plant olarak bilinmektedir.
                </p>
            </div>
        </>
    );
};

export default GES;