import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';

function Construction() {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [
        "https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/construction%2Fconstruction1.svg?alt=media&token=da6df061-6b27-45a4-83e3-530ea44df03f",
        "https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/construction%2Fconstruction2.svg?alt=media&token=78800873-83e8-46b4-8bf9-e752243828fc"
    ];

    const imagesCompeleted = [
        "https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/construction%2Fatacenter.svg?alt=media&token=196406fa-ae7d-488b-a72a-c6d5ec4fe377",
        "https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/construction%2Fnese_park.svg?alt=media&token=43177ac6-356d-4548-be49-1b6662c0f32f",
        "https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/construction%2Fvista_park.svg?alt=media&token=83df7960-299a-4449-817a-c0e09228d5a0",
        "https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/construction%2Fnoname.svg?alt=media&token=569f0b33-befd-4562-b2c5-9654ec0699b0"
    ];

    const captions = [
        'SAĞLAM YAPILAR', 've', 'GÜVENİLİR MALZEME',
        'YÜKSEK KALİTELİ', 'HİZMET', ''
    ];

    const imageCompletedTexts = [
        'Atacenter İş Merkezi',
        'Neşepark Evleri',
        'Vista Park',
        '',
    ];
    const imageCompletedUrl = [
        '',
        'https://www.mnfides.com/detay.php?id=6721&dl=tr',
        'https://www.mnfides.com/detay.php?id=6725&dl=tr',
        '',
    ];

    const imagesContinued = ["https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/construction%2Fvega_park.svg?alt=media&token=9d8cbe62-ebf0-4e20-81bf-6229e30966bb"];

    const imageContinuedTexts = [
        'Vega Park'
    ];

    const imageContinuedUrl = [
        'https://www.mnfides.com/detay.php?id=6726&dl=tr'
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, []);

    const goToNextSlide = () => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    };

    const goToPrevSlide = () => {
        setCurrentImageIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    return (
        <>
            <Header />
            <div>
                <div className="relative mb-5 md:mb-16 lg:mb-24 overflow-hidden">
                    <img className="w-full h-auto object-cover" src={images[currentImageIndex]} alt={`Resim ${currentImageIndex + 1}`} />
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white font-bold px-4 py-2 lg:text-5xl font-serif tracking-wider">
                        <div className='lg:mb-4'>{captions[currentImageIndex * 3]}</div>
                        <div className='lg:mb-4'>{captions[currentImageIndex * 3 + 1]}</div>
                        <div>{captions[currentImageIndex * 3 + 2]}</div>
                    </div>
                    <div className="absolute top-1/2 transform -translate-y-1/2 left-0 right-0 flex justify-between">
                        <button className="bg-gray-500 bg-opacity-70 text-white px-3 py-2 rounded-l focus:outline-none focus:ring-2 focus:ring-gray-400" onClick={goToPrevSlide}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M14.707 2.293a1 1 0 0 1 0 1.414L8.414 10l6.293 6.293a1 1 0 1 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                            </svg>
                        </button>
                        <button className="bg-gray-500 bg-opacity-70 text-white px-3 py-2 rounded-r focus:outline-none focus:ring-2 focus:ring-gray-400" onClick={goToNextSlide}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M5.293 2.293a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1 0 1.414l-7 7a1 1 0 1 1-1.414-1.414L10.586 10 5.293 4.707a1 1 0 0 1 0-1.414z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className='bg-gray-100 shadow-lg mb-16 lg:mb-24 text-gray-900'>
                    <p className='text-center text-md md:text-3xl lg:text-4xl xl:text-3xl font-bold p-6 font-serif'>TAMAMLANAN PROJELERİMİZ</p>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 gap-2 lg:px-24'>
                        {imagesCompeleted.map((image, index) => (
                            <div key={index} className="group flex flex-col items-center relative overflow-hidden">
                                <a href={imageCompletedUrl[index]}>
                                    <div className="relative">
                                        <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} className="h-36 md:h-48 lg:h-64 xl:h-24 max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-125 group-hover:opacity-50 mx-auto" src={image} alt="" />
                                        <div className="absolute inset-0 flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 border border-gray-500 border-md mx-3 my-3">
                                            <p className="text-white text-lg font-semibold text-center">{imageCompletedTexts[index]}</p>
                                            <div className="flex justify-center">
                                                <img className="w-20 h-8" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/base%2F1.svg?alt=media&token=816a9ca2-0e82-473f-a8a7-85b35a0de44b" alt="Arkaplan Resmi" />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>


                </div>

                <div className='bg-gray-100 shadow-lg mb-16 lg:mb-24 text-gray-900'>
                    <p className='text-center text-md md:text-3xl lg:text-4xl xl:text-3xl font-bold p-6 font-serif'>DEVAM EDEN PROJELERİMİZ</p>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 gap-2 lg:px-24'>
                        {imagesContinued.map((image, index) => (
                            <div key={index} className="group flex flex-col items-center relative overflow-hidden">
                                <a href={imageContinuedUrl[index]}>
                                    <div className="relative">
                                        <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} className="h-36 md:h-48 lg:h-64 xl:h-24 max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-125 group-hover:opacity-50 mx-auto" src={image} alt="" />
                                        <div className="absolute inset-0 flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 border border-gray-500 border-md mx-3 my-3">
                                            <p className="text-white text-lg font-semibold text-center">{imageContinuedTexts[index]}</p>
                                            <div className="flex justify-center">
                                                <img className="w-20 h-8" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/base%2F1.svg?alt=media&token=816a9ca2-0e82-473f-a8a7-85b35a0de44b" alt="Arkaplan Resmi" />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Construction;
