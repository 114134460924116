import React from 'react';
import Header2 from '../../components/header/Header2';

import { Link } from 'react-router-dom';

const Home = () => {

    const handleButtonClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <>
            <Header2 />
            <div className=''>
                <div className="mb-16 lg:mb-24">
                    <div className="bg-gray-100 h-auto w-screen rounded flex flex-col lg:flex-row justify-end items-center lg:pr-96 text-xs lg:text-base shadow-lg p-1 lg:p-3">
                        <div className="lg:mr-96 lg:font-bold lg:italic">
                            <p className="italic font-bold">Bir Şirketler Grubu olarak İÇTENLER...</p>
                        </div>
                        <Link to="/about">
                            <button
                                className="bg-yellow-500 text-black px-2 py-1 lg:px-3 lg:py-1 rounded font-bold lg:ml-4 mt-2 lg:mt-0 hover:bg-gray-300"
                                onClick={handleButtonClick}
                            >
                                Biz Kimiz? ➤
                            </button>
                        </Link>
                    </div>
                </div>

                <div className='bg-gray-100 shadow-lg mb-16 lg:mb-24 text-blue-900'>
                    <p className='text-center text-md md:text-3xl lg:text-4xl xl:text-3xl font-bold p-6 font-serif'>Faaliyet Gösterdiğimiz Sektörler</p>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-2 p-6 lg:px-36'>
                        <div className="group flex flex-col items-center">
                            <p className='text-start text-sm md:text-2xl lg:text-4xl xl:text-2xl font-bold py-3 lg:py-5'>Kuyumculuk</p>
                            <Link to='/jeweler' onClick={handleButtonClick}>
                                <img className="h-36 md:h-48 lg:h-64 xl:h-80 max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110 mx-auto" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/home%2Fhome5.svg?alt=media&token=1f4291bc-c41a-43ce-a7ee-f63b1799716a" alt="" />
                            </Link>
                        </div>
                        <div className="group flex flex-col items-center">
                            <p className='text-start text-sm md:text-2xl lg:text-4xl xl:text-2xl font-bold py-3 lg:py-5'>Akaryakıt</p>
                            <Link to='/fueloil' onClick={handleButtonClick}>
                                <img className="h-36 md:h-48 lg:h-64 xl:h-80 max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110 mx-auto" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/home%2Fhome3.svg?alt=media&token=fcf3eb83-e9f2-4e91-841e-0fe52257f91f" alt="" />
                            </Link>
                        </div>
                        <div className="group flex flex-col items-center">
                            <p className='text-start text-sm md:text-2xl lg:text-4xl xl:text-2xl font-bold py-3 lg:py-5'>Giyim Market</p>
                            <Link to='/clothing' onClick={handleButtonClick}>
                                <img className="h-36 md:h-48 lg:h-64 xl:h-80 max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110 mx-auto" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/home%2Fhome6.svg?alt=media&token=614c18c4-cb88-4926-a1f5-c36fefbd4bc9" alt="" />
                            </Link>
                        </div>
                        <div className="group flex flex-col items-center">
                            <p className='text-start text-sm md:text-2xl lg:text-4xl xl:text-2xl font-bold py-3 lg:py-5'>İnşaat</p>
                            <Link to='/construction' onClick={handleButtonClick}>
                                <img className="h-36 md:h-48 lg:h-64 xl:h-80 max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110 mx-auto" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/home%2Fhome4.svg?alt=media&token=c769a39a-26fa-4083-9c28-b3d3fae27828" alt="" />
                            </Link>
                        </div>
                        <div className="group flex flex-col items-center">
                            <p className='text-start text-sm md:text-2xl lg:text-4xl xl:text-2xl font-bold py-3 lg:py-5'>GES</p>
                            <Link to='/ges' onClick={handleButtonClick}>
                                <img className="h-36 md:h-48 lg:h-64 xl:h-80 max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110 mx-auto" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/ges%2Fges_cover.svg?alt=media&token=a99f5370-4e7e-4558-bb87-4c3e40028fab" alt="" />
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-100 shadow-lg mb-16 lg:mb-24 py-2 lg:py-6 relative">
                    <p className="text-center text-xl md:text-3xl lg:text-4xl xl:text-3xl py-6 lg:p-8 text-blue-900 font-serif mb-4">
                        İÇTENLER ŞİRKETLER GRUBU olarak;
                    </p>
                    <div className="relative text-center mb-6 lg:mb-16">
                        <div className="group">
                            <img className="w-full h-48 object-cover lg:h-96 filter brightness-75 hover:brightness-100 transition-all duration-300" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/home%2Fhome1.svg?alt=media&token=57228f63-4f07-48a5-b88d-7b4e5c5e416d" alt="Arkaplan Resmi" />
                            <div className="absolute inset-0 flex flex-col items-start justify-center text-white p-4 lg:px-48">
                                <div className="metin1">
                                    <p className="text-xl md:text-3xl lg:text-4xl xl:text-3xl font-bold text-yellow-300">
                                        Sürekli Eğitim-Değişim-Yatırım
                                    </p>
                                </div>
                                <div className="metin2">
                                    <p className="text-xl md:text-3xl lg:text-4xl xl:text-3xl font-bold">
                                        İş felsefemiz ile;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative text-start">
                        <div className="group">
                            <img className="w-full h-32 object-cover lg:h-48 mb-4 lg:mb-8 filter brightness-75 hover:brightness-100 transition-all duration-300" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/home%2Fhome2.svg?alt=media&token=9ca9a099-152b-425a-afbb-09c15e7a5167" alt="Arkaplan Resmi" />
                            <div className="absolute inset-0 flex flex-col items-start justify-center text-white p-4 lg:px-48">
                                <div className="metin3">
                                    <p className="text-md md:text-3xl lg:text-4xl xl:text-3xl font-bold">
                                        Büyüyoruz, Büyütüyoruz! Faaliyette bulunduğumuz tüm sektörlerde;
                                    </p>
                                </div>
                                <div className="metin4">
                                    <p className="text-md md:text-3xl lg:text-4xl xl:text-3xl font-bold text-yellow-300">
                                        tedarikçisi olduğumuz işletmeleri de büyütüyoruz.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="flex overflow-x-auto mb-16 lg:mb-24">
                    <div className="flex-shrink-0 w-48 h-32 lg:h-44 m-4 flex justify-center items-center bg-gray-100 shadow-lg p-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/references%2Fbpet.svg?alt=media&token=77fa1cb7-3f55-4c9e-bd5d-fe7605c3a72b" alt="Bpet" className="max-h-full max-w-full" />
                    </div>
                    <div className="flex-shrink-0 w-48 h-32 lg:h-44 m-4 flex justify-center items-center bg-gray-100 shadow-lg p-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/references%2Fgold.svg?alt=media&token=dd66ea24-e4d7-47b9-9002-1a0d82fc6eea" alt="Gold" className="max-h-full max-w-full" />
                    </div>
                    <div className="flex-shrink-0 w-48 h-32 lg:h-44 m-4 flex justify-center items-center bg-gray-100 shadow-lg p-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/references%2Flumberjack.svg?alt=media&token=29c5feb8-cc7e-4268-8847-95b610e5ce8b" alt="Lumberjack" className="max-h-full max-w-full" />
                    </div>
                    <div className="flex-shrink-0 w-48 h-32 lg:h-44 m-4 flex justify-center items-center bg-gray-100 shadow-lg p-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/references%2Fkinetix.svg?alt=media&token=22f06133-47b0-4b10-81d8-8b0240166846" alt="Kinetix" className="max-h-full max-w-full" />
                    </div>
                    <div className="flex-shrink-0 w-48 h-32 lg:h-44 m-4 flex justify-center items-center bg-gray-100 shadow-lg p-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/references%2Fus_polo_assn.svg?alt=media&token=35cd96e9-559d-4ab4-a5df-0656b0812c53" alt="Us Polo Assn" className="max-h-full max-w-full" />
                    </div>
                    <div className="flex-shrink-0 w-48 h-32 lg:h-44 m-4 flex justify-center items-center bg-gray-100 shadow-lg p-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/references%2Fpuane.svg?alt=media&token=0088ae84-6eac-41a7-b389-40ca275628d6" alt="Puane" className="max-h-full max-w-full" />
                    </div>
                    <div className="flex-shrink-0 w-48 h-32 lg:h-44 m-4 flex justify-center items-center bg-gray-100 shadow-lg p-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/references%2Fikoll.svg?alt=media&token=c7577c51-5541-4844-8b9e-2b50e20a6d04" alt="Ikoll" className="max-h-full max-w-full" />
                    </div>
                    <div className="flex-shrink-0 w-48 h-32 lg:h-44 m-4 flex justify-center items-center bg-gray-100 shadow-lg p-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/references%2Fozdilek.svg?alt=media&token=e3ec3b34-94e0-4ecb-ad0a-0b67ab1a5e1a" alt="Ozdilek" className="max-h-full max-w-full" />
                    </div>


                </div>

                <div className="flex bg-gray-100 flex px-8 mb-16 lg:mb-24 shadow-xl">
                    <div className="">
                        <h1 className="flex justify-center text-md lg:text-lg font-bold lg:mb-2 text-blue-900 mt-4">İÇTENLER GRUP</h1>
                        <h1 className="flex justify-center text-lg lg:text-xl font-bold mb-4 lg:mb-8 text-blue-900">Yönetim Kurulunun Mesajı</h1>
                        <p className="text-sm lg:text-lg lg:px-12 font-serif text-gray-500 mb-4 lg:mb-4">
                            ❝ Başlıca amacımız; ülkemize katma değer sağlayarak, insanımıza ve gelecek nesillere faydalı olabilmektir. Ayrıca geleceği inşa etme vizyonumuzla; yeni yapılar inşa etmek, turizmi geliştirmek, sanayi ve enerji ile sınırlı kalmayıp, başka sanayi dallarına da atılarak, ülkemizin dışa bağımlı olmasının önüne geçmektir. ❞
                        </p>
                        <h1 className="flex justify-center text-md lg:text-xl text-gray-500 font-serif lg:px-12 lg:mb-6 mb-6">Yönetim Kurulu</h1>
                    </div>
                </div>

            </div>
        </>
    );
}
export default Home;
