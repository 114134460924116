import React from "react";
import Header from '../../components/header/Header';
import Phone from "../../assets/icons/Phone";
import Location from "../../assets/icons/Location";

function Fueloil() {
    return (
        <>
            <Header />
            <div>
                <div>
                    <div className="relative mb-10 lg:mb-20">
                        <img className="w-full object-cover h-auto" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/fueloil%2Ffueloil1.svg?alt=media&token=10d117a7-6369-4532-bc66-241dd6c432bf" alt="Arkaplan Resmi" />
                        <div className="absolute inset-0 text-white lg:py-64 p-12 lg:px-48 md:py-24">
                            <div className=''>
                                <p className="text-md md:text-3xl lg:text-4xl xl:text-3xl font-bold sm:text-sm">
                                    Gelişmiş araçlar için;
                                </p>
                            </div>
                            <div className=''>
                                <p className="text-md  md:text-3xl lg:text-4xl xl:text-3xl font-bold lg:px-32 text-yellow-300 px-12 md:px-24">
                                    Gelişmiş yakıtlar...
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="mx-auto lg:px-2 lg:py-6 px-2 py-4 bg-gray-100 mb-12 lg:mb-20 font-bold text-sm lg:text-2xl md:text-xl lg:text-2xl w-full">
                        <p className="flex justify-center items-center font-style: italic">bpet</p>
                        <p className="flex justify-center items-center"> Akaryakıt hizmetlerimiz</p>
                    </div>
                </div>

                <div className="bg-gray-100 mb-10 lg:mb-20 relative">
                    <img className="w-full object-cover h:64 lg:h-96" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/fueloil%2Ffueloil2.svg?alt=media&token=ea614a04-c4e2-4bfd-9c8c-8c9517c8d361" alt="Arkaplan Resmi" />
                </div>

                <div className="bg-gray-100 mb-24 relative">
                    <div className="mx-auto lg:px-2 lg:py-6 px-2 py-4 font-bold text-sm lg:text-2xl md:text-xl text-md w-full">
                        <p className="flex justify-center items-center font-style: italic text-black">bpet</p>
                        <p className="flex justify-center items-center text-black">İstasyonlarımız</p>
                        <div className="flex justify-center items-center text-white lg:py-16 lg:px-48">
                            <div className="flex flex-col lg:flex-row lg:space-x-24 space-x-2">
                                <div className="border rounded-lg text-start lg:text-sm text-xs bg-opacity-40 lg:p-4 p-2 mb-4 lg:mb-0 bg-gray-300 shadow-lg mt-8 ">
                                    <div className="text-gray-700">
                                        <p className="py-1 px-2">YEŞİLYURT İÇTENLER PETROL</p>
                                    </div>
                                    <hr />
                                    <div className="text-gray-700">
                                        <div className="inline-block rounded-md lg:p-1 lg:mt-1">
                                            <Location />
                                        </div>
                                        <p className="px-2 text-xs mt-1">
                                            Adres :
                                        </p>
                                        <p className="px-2 mb-1 py-1">Şehitler, 60860 Yeşilyurt/Tokat</p>
                                    </div>
                                    <div className="text-gray-700">
                                        <div className="inline-block rounded-md lg:p-1">
                                            <Phone />
                                        </div>
                                        <p className="px-2 text-xs">
                                            Telefon :
                                        </p>
                                        <p className="px-2 py-1">+90 505 256 14 89</p>
                                    </div>
                                </div>

                                <div className="border rounded-lg text-start lg:text-sm text-xs bg-opacity-40 lg:p-4 p-2 mb-4 lg:mb-0 bg-gray-300 shadow-lg mt-8">
                                    <div className="text-gray-700">
                                        <p className="py-1 px-2">YEŞİLYURT İÇTENLER PETROL 2</p>
                                    </div>
                                    <hr />
                                    <div className="text-gray-700">
                                        <div className="inline-block rounded-md lg:p-1 lg:mt-1">
                                            <Location />
                                        </div>
                                        <p className="px-2 text-xs mt-1">
                                            Adres :
                                        </p>
                                        <p className="px-2 mb-1 py-1">Gaziosmanpaşa, 60860 Yeşilyurt/Tokat</p>
                                    </div>
                                    <div className="text-gray-700">
                                        <div className="inline-block rounded-md lg:p-1">
                                            <Phone />
                                        </div>
                                        <p className="px-2 text-xs">
                                            Telefon :
                                        </p>
                                        <p className="px-2 py-1">+90 505 256 14 89</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Fueloil;
