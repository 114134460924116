import { React, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown1';

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    document.addEventListener("DOMContentLoaded", function () {
        var currentPage = window.location.pathname;

        if (currentPage === "/") {
            document.getElementById("anasayfa-link").classList.add("font-bold");
        }
    });

    return (
        <header className="shadow-lg">
            <div className="mx-auto max-w-7xl p-6 lg:px-8 flex justify-between items-center">
                <div className="flex items-center">
                    <Link to={"/"}>
                        <span className="sr-only">İçtenler Grup</span>
                        <img className="h-12 w-auto" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/base%2F1.svg?alt=media&token=816a9ca2-0e82-473f-a8a7-85b35a0de44b" alt="İçtenler Grup" />
                    </Link>
                </div>
                <div className="hidden lg:flex items-center space-x-12 font-bold font-serif">
                    <Link to="/" className="leading-6 text-gray-500 hover:text-gray-700">
                        Ana Sayfa
                    </Link>

                    <Dropdown className="leading-6 text-gray-500 hover:text-gray-700">
                        Faaliyet Alanlarımız
                    </Dropdown>

                    <Link to={"/about"} className='leading-6 text-gray-500 hover:text-gray-700'>
                        Kurumsal
                    </Link>

                    <Link to="/contact" className="leading-6 text-gray-500 hover:text-gray-700">
                        İletişim
                    </Link>
                </div>
                <div className="lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-500"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </div>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">İçtenler Grup</span>
                            <img className="h-8 w-auto" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/base%2F1.svg?alt=media&token=816a9ca2-0e82-473f-a8a7-85b35a0de44b" alt="" />
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6">
                        <nav className="grid gap-y-4 font-serif">
                            <Link to="/" className="text-base font-medium text-gray-700 hover:text-gray-500" onClick={() => setMobileMenuOpen(false)}>
                                Ana Sayfa
                            </Link>
                            <Dropdown className="text-base font-medium text-gray-700 hover:text-gray-500" onClick={() => setMobileMenuOpen(false)}>
                                Faaliyet Alanlarımız
                            </Dropdown>
                            <Link to="/about" className="text-base font-medium text-gray-700 hover:text-gray-500" onClick={() => setMobileMenuOpen(false)}>
                                Kurumsal
                            </Link>
                            <Link to="/contact" className="text-base font-medium text-gray-700 hover:text-gray-500" onClick={() => setMobileMenuOpen(false)}>
                                İletişim
                            </Link>
                        </nav>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
}



