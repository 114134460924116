import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const Dropdown = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    return (
        <div className="relative group" ref={dropdownRef}>
            <button onClick={toggleDropdown} className="leading-6 lg:text-orange-100 text-yellow-900 lg:hover:text-orange-50 hover:text-yellow-700">
                {children}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-4 inline-block ml-1 -mt-1 transition-transform duration-300 transform"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0)' }}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </button>
            {isOpen && (
                <div className="absolute z-10 -ml-4 mt-2 w-40 bg-white border border-gray-200 shadow-lg rounded-lg py-2">
                    <Link to="/jeweler" onClick={handleLinkClick} className="block px-4 py-2 text-sm text-gray-700 lg:text-gray-500 hover:bg-gray-200 ">Kuyumcu</Link>
                    <Link to="/fueloil" onClick={handleLinkClick} className="block px-4 py-2 text-sm text-gray-700 lg:text-gray-500 hover:bg-gray-200">Akaryakıt</Link>
                    <Link to="/clothing" onClick={handleLinkClick} className="block px-4 py-2 text-sm text-gray-700 lg:text-gray-500 hover:bg-gray-200">Giyim</Link>
                    <Link to="/construction" onClick={handleLinkClick} className="block px-4 py-2 text-sm text-gray-700 lg:text-gray-500 hover:bg-gray-200">İnşaat</Link>
                    <Link to="/ges" onClick={handleLinkClick} className="block px-4 py-2 text-sm text-gray-700 lg:text-gray-500 hover:bg-gray-200">GES</Link>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
