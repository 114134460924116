import React from "react";
import Router from "./router/Routers";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <>
      <Router />
      <Footer />
    </>
  );
};

export default App;
