import React from 'react';
import Header from '../../components/header/Header';

const ContactPage = () => {
    return (
        <>
            <Header />
            <div className='flex justify-center'>
                <div className="flex flex-col justify-between bg-white rounded-lg shadow-md w-full max-w-md m-12">
                    <div className='flex justify-center'>
                        <iframe
                            title="İçtenler Grup"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.054302801878!2d36.21794833919757!3d40.00723459097554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x407e7ea0848131f1%3A0x3ce11f63848d9bf2!2sI%C3%A7tenler%20Grup!5e0!3m2!1str!2str!4v1709229003588!5m2!1str!2str"
                            width="100%"
                            height="300"
                            style={{ border: 0 }}
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className='flex justify-center flex-col mx-6 p-6'>
                        <h1 className="text-2xl font-semibold mb-4">İçtenler Grup</h1>
                        <p className="text-gray-700 mb-4">
                            Adres: 100. Yıl, Atatürk Blv., 60860 Yeşilyurt/Tokat
                        </p>
                        <p className="text-gray-700 mb-4">
                            Telefon: 0356 631 26 05
                        </p>
                        <p className="text-gray-700 mb-4">
                            E-posta: ictenlergrupp@hotmail.com
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactPage;
