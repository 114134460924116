import React from "react";
import Header from '../../components/header/Header';

function Jeweler() {
    return (
        <>
            <Header />
            <>
                <div>
                    <div className="relative mb-10 lg:mb-20 shadow-xl">
                        <img className="w-full object-cover h-auto" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/jeweler%2Fjeweler1.svg?alt=media&token=b7f2953e-1a81-4f5d-8b65-6734727b4a72" alt="Arkaplan Resmi" />
                    </div>

                    <div class="flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-between mx-auto lg:px-2 lg:py-6 px-2 py-4 bg-gray-100 mb-12 lg:mb-20 text-sm lg:text-lg md:text-xl lg:text-2xl w-full shadow-xl">
                        <div class="lg:w-1/2 lg:pr-6 mb-4 lg:mb-0 px-6">
                            <h1 class="text-gray-700 text-md lg:text-lg font-bold mb-2 lg:mb-4 md:mb-4">Parıltının Adresi İçtenler Kuyumculuk</h1>
                            <p class="text-xs md:text-md lg:text-lg flex justify-start items-center mb-2 lg:mb-4 md:mb-4">Hayallerinizi süsleyen mücevher ve takılarla dolu bir dünyaya hoş geldiniz! İçtenler Kuyumculuk kaliteli ve özgün tasarımlarıyla göz kamaştırıyor. Her bir parça, ustaların emeğiyle şekillendirilmiş, değerli ve eşsizdir.
                                Müşteri memnuniyetini ilke edinen İçtenler Kuyumculuk, geniş ürün yelpazesiyle her zevke ve bütçeye hitap ediyor. </p>
                            <p class="text-xs md:text-md lg:text-lg flex justify-start items-center mb-2 lg:mb-4 md:mb-4">Nişan, düğün, doğum günü veya özel günleriniz için ideal hediye seçenekleri burada sizi bekliyor.
                                Deneyimli ve güler yüzlü personelimiz, sizlere en iyi alışveriş deneyimini sunmak için burada. Size özel bir parçayı bulmanıza yardımcı olmak için sabırsızlanıyoruz.
                                Bizi ziyaret edin ve değerli anlarınıza ışıltı katın! İçtenlet Kuyumculuk, parıltının adresidir.</p>
                        </div>
                        <div class="lg:w-1/2">
                            <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/jeweler%2Fjeweler2.svg?alt=media&token=21f01c38-6f7e-4718-8756-3e341d493e7b" alt="Fotoğraf" class="w-full h-auto lg:rounded-lg" />
                        </div>
                    </div>

                    <div className="relative">
                        <img className="w-full object-cover h-auto mb-4" src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/jeweler%2Fjeweler_background.svg?alt=media&token=3686ab68-30c6-4a96-92cc-5fe784700525" alt="Arkaplan Resmi" />
                    </div>

                    <div class="bg-gray-100 mb-24 shadow-lg font-serif">
                        <div>
                            <h1 class="text-md lg:text-xl font-bold mb-2 lg:mb-8 md:mb-4 text-center text-gray-700">Kalite ve Güvenin Tek Adresi</h1>
                            <div class="flex justify-between text-gray-500">
                                <div class="w-1/3 text-left flex flex-col items-center">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/jeweler%2Fjeweler4.svg?alt=media&token=5027e2ff-970d-4245-865b-edd5ec02b934" alt="Müşteri Memnuniyeti" class="w-10 h-10 mb-1" />
                                    <p class="text-xs md:text-md lg:text-lg mb-12">MEMNUNİYET</p>
                                </div>
                                <div class="w-1/3 text-left flex flex-col items-center">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/jeweler%2Fjeweler5.svg?alt=media&token=9f4321db-8ffc-4381-a528-3481b4159390" alt="Müşteri Memnuniyeti" class="w-10 h-10 mb-1" />
                                    <p class="text-xs md:text-md lg:text-lg mb-12">GÜVENİLİRLİK</p>
                                </div>
                                <div class="w-1/3 text-left flex flex-col items-center">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/ictenler-grup.appspot.com/o/jeweler%2Fjeweler6.svg?alt=media&token=7eed7e5f-0eba-4826-be1b-4cc9a7b00ae8" alt="Müşteri Memnuniyeti" class="w-10 h-10 mb-1" />
                                    <p class="text-xs md:text-md lg:text-lg mb-12">TANINMIŞ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

export default Jeweler;